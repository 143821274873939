<template>
  <div
    v-if="layout.path === '/' || layout.path.includes('/game/melody/report')"
    :tpd="resolution.width > resolution.notebook ? `${scope === 'index' ? `` : `3xl`}` : ``"
    class="box"
    gap="5xs"
  >
    <v-btn
      to="/game/melody/report"
      :title="$t('game-melody-report-best')"
      :line="isBest ? `3xs-t-secondary` : `3xs-t-4xs`"
      flex="1"
      variant="flat"
      class="rounded-0"
      :height="resolution.width > resolution.mobile ? 60 : `100%`"
      :bg="isBest ? `transparent` : `5xs`"
      extent="wm h-auto"
      fsize="md"
    >
      <strong class="box" gap="3xs" pd="md-y" halign="center" :direction="resolution.width > resolution.mobile ? `` : `column`" :color="isBest ? `secondary` : `3xl`" break="initial">
        <span>👑</span>
        {{ $t('game-melody-report-best') }}
      </strong>
    </v-btn>
    <v-btn
      to="/game/melody/report/comment"
      :title="$t('game-melody-report-comment')"
      :line="isComment ? `3xs-t-secondary` : `3xs-t-4xs`"
      flex="1"
      variant="flat"
      class="rounded-0"
      :height="resolution.width > resolution.mobile ? 60 : `100%`"
      extent="wm h-auto"
      :bg="isComment ? `transparent` : `5xs`"
      fsize="md"
    >
      <strong class="box" gap="3xs" pd="md-y" halign="center" :direction="resolution.width > resolution.mobile ? `` : `column`" :color="isComment ? `secondary` : `3xl`" break="initial">
        <span>💬</span>
        {{ $t('game-melody-report-comment') }}
      </strong>
    </v-btn>
    <v-btn
      to="/game/melody/report/playing"
      :line="isPlaying ? `3xs-t-secondary` : `3xs-t-4xs`"
      :title="$t('game-melody-report-playing')"
      flex="1"
      variant="flat"
      class="rounded-0"
      :height="resolution.width > resolution.mobile ? 60 : `100%`"
      extent="wm h-auto"
      :bg="isPlaying ? `transparent` : `5xs`"
      fsize="md"
    >
      <strong class="box" gap="3xs" pd="md-y" halign="center" :direction="resolution.width > resolution.mobile ? `` : `column`" :color="isPlaying ? `secondary` : `3xl`" break="initial">
        <span>🎮</span>
        {{ $t('game-melody-report-playing') }}
      </strong>
    </v-btn>
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '~/store/global'

export default {
  setup () {

    // 글로벌 스토어
    const { layout } = storeToRefs(useGlobalStore())
    const { resolution } = storeToRefs(useGlobalStore())

    let isBest: any = ref(false)
    let isComment: any = ref(false)
    let isPlaying: any = ref(false)
    function setMenu () {
      isBest.value = layout.value.path === '/game/melody/report' ? true : false
      isComment.value = layout.value.path === '/game/melody/report/comment' ? true : false
      isPlaying.value = layout.value.path === '/game/melody/report/playing' ? true : false
    }
    setMenu()

    // 메모리 비우기
    function restore () {
      isBest.value = null
      isComment.value = null
      isPlaying.value = null
    }

    // 
    onBeforeUnmount (() => {
      restore()
    })

    return {
      layout,
      resolution,
      isBest,
      isComment,
      isPlaying,
      setMenu
    }
  },
  props: {
    scope: {
      type: String,
      default () {
        return ''
      }
    }
  },
  watch: {
    '$route' (to, from) {
      this.setMenu()
    }
  }
}
</script>